export enum Symptom {
  LAMP_FLICKERS = "LAMP_FLICKERS",
  LAMP_HUMS = "LAMP_HUMS",
  LAMP_MISSING = "LAMP_MISSING",
  WRONG_LIGHTING_AREA = "WRONG_LIGHTING_AREA",
  LAMP_INGROWN = "LAMP_INGROWN",
  LAMPGLASS_DAMAGED_MISSING = "LAMPGLASS_DAMAGED_MISSING",
  ONE_LAMP_OFF = "ONE_LAMP_OFF",
  SEVERAL_LAMPS_OFF = "SEVERAL_LAMPS_OFF",
  MAST_OR_SWITCH_DEFECT = "MAST_OR_SWITCH_DEFECT",
  CABLE_DAMAGED = "CABLE_DAMAGED",
  OTHER = "OTHER",
}

export const SYMPTOM_LABEL: { [k in Symptom]: string } = {
  LAMP_FLICKERS: "Leuchte flackert",
  LAMP_HUMS: "Leuchte brummt",
  LAMP_MISSING: "Leuchte fehlt",
  WRONG_LIGHTING_AREA: "Falscher Leuchtenbereich",
  LAMP_INGROWN: "Leuchte eingewachsen",
  LAMPGLASS_DAMAGED_MISSING: "Leuchtenglas defekt",
  ONE_LAMP_OFF: "Eine Leuchte ist aus",
  SEVERAL_LAMPS_OFF: "Mehrere Leuchten in derselben Straße aus",
  MAST_OR_SWITCH_DEFECT: "Mast /Schaltschrank ist beschädigt",
  CABLE_DAMAGED: "Kabel/Leitung defekt",
  OTHER: "Sonstiges",
};

export const EMERGENCY_SYMPTOMS = [
  Symptom.SEVERAL_LAMPS_OFF,
  Symptom.MAST_OR_SWITCH_DEFECT,
  Symptom.CABLE_DAMAGED,
  Symptom.OTHER,
];
