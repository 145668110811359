import { format } from "date-fns";
import { Button, styled, Typography } from "@mui/material";
import { InfoWindow as GoogleInfoWindow } from "@react-google-maps/api";
import { LightPoint } from "../../models/LightPoint";
import { IncidentLightingPointRelation as Incident } from "../../models/IncidentLightingPointRelation";
import { LionStateLabel } from "../../models/LionState";
import {
  IncidentStatus,
  IncidentStatusLabel,
  isIncidentInProgress,
  isIncidentRequiredApproval
} from "../../models/IncidentStatus";

interface Props {
  lightPoint: LightPoint;
  incident?: Incident;
  lat: number;
  lng: number;
  onClose: () => void;
  onShowFaultReport?: (incidentUuid: string) => void;
}

export const InfoWindow = ({ lightPoint, incident, onClose, lat, lng, onShowFaultReport }: Props) => {

  function getIncidentStatusLabel(incidentStatus: IncidentStatus): string {
    if (isIncidentInProgress(incidentStatus)) {
      return "Störung gemeldet, Freigabe der Kommune erteilt";
    }
    if (isIncidentRequiredApproval(incidentStatus)) {
      return "Störung gemeldet, Freigabe der Kommune erforderlich";
    }
    return "Störung gemeldet";
  };

  return (
    <StyledGoogleInfoWindow position={{ lat, lng }} onCloseClick={onClose} options={{ disableAutoPan: true }}>
      <div className={classes.infoWindow}>
        <div style={{ fontSize: 16 }}>{lightPoint.number}</div>
        <Typography style={{ color: "grey", fontSize: 14, marginBottom: incident ? 15 : 0 }}>
          {lightPoint.street ? `${lightPoint.street} ${!!lightPoint.houseNumber ? lightPoint.houseNumber : ""}` : ""}
        </Typography>
        {incident && (
          <div style={{ display: "flex", flexDirection: "column", gap: 10 }}>
            <div style={{display: "flex"}}>
              <strong style={{ width: "40%" }}>Status:</strong>
              <span style={{ width: "100%" }}>{!onShowFaultReport ? getIncidentStatusLabel(incident.incidentStatus) : IncidentStatusLabel[incident.incidentStatus]}</span>
            </div>
            <div style={{display: "flex"}}>
              <strong style={{ width: "40%" }}>Gemeldet:</strong>
              <span style={{ width: "100%" }}>
                {incident.reportingTimestamp ? format(new Date(incident.reportingTimestamp), "dd.MM.yyyy hh:mm") : "-"}
              </span>
            </div>
            {onShowFaultReport ? (
              <Button
                color="secondary"
                variant="text"
                style={{ alignSelf: "flex-start" }}
                onClick={() => onShowFaultReport(incident.incidentUuid)}
              >
                Störungsmeldung anzeigen
              </Button>
            ) : (
              <span>Diese Leuchtstelle kann nicht ausgewählt werden.</span>
            )}
          </div>
        )}
      </div>
    </StyledGoogleInfoWindow>
  );
};

const PREFIX = "InfoWindow";

const classes = {
  infoWindow: `${PREFIX}-infoWindow`,
  header: `${PREFIX}-header`,
  closeIcon: `${PREFIX}-closeIcon`,
};

const StyledGoogleInfoWindow = styled(GoogleInfoWindow)({
  [`& .${classes.infoWindow}`]: {
    maxWidth: 250,
    backgroundColor: "white",
    boxShadow: "0 2px 7px 1px rgba(0, 0, 0, 0.3)",
    zIndex: 100,
  },
  [`& .${classes.header}`]: {
    display: "flex",
    justifyContent: "space-between",
    alignItems: "center",
  },
  [`& .${classes.closeIcon}`]: {
    cursor: "pointer",
  },
});
