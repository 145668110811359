import { Checkbox, Typography, Grid, FormGroup, FormControlLabel, TextField } from "@mui/material";
import { Box } from "@mui/system";
import React, { useState } from "react";
import { EMERGENCY_SYMPTOMS, Symptom, SYMPTOM_LABEL } from "../../../models/Symptom";
import { EmergencyDialog } from "../../../parts";

interface SymptomSelectBoxValue {
  symptoms: Symptom[];
  symptomOther?: string;
}

export interface SymptomSelectBoxProps {
  symptomBoxValue: SymptomSelectBoxValue;
  onSelectSymptoms: (s: Symptom[]) => void;
  onSymptomOther: (s: string) => void;
}

export const FaultSymptoms = (props: SymptomSelectBoxProps) => {
  const [symptoms, setSymptoms] = useState<Symptom[]>(props.symptomBoxValue.symptoms);
  const [symptomOther, setSymptomOther] = useState(props.symptomBoxValue.symptomOther);
  const [openEmergencyDialog, setOpenEmergencyDialog] = useState(false);

  const onCheckBoxChange = (checked: boolean, symptom: Symptom) => {
    if (EMERGENCY_SYMPTOMS.includes(symptom)) {
      return setOpenEmergencyDialog(true);
    }
    const selectedSymptoms = checked 
      ? [...symptoms, symptom]
      : symptoms.filter(s => s !== symptom);
    
    setSymptoms(selectedSymptoms);
    props.onSelectSymptoms(selectedSymptoms);
  };

  function handleSymptomOtherChange(v: string) {
    setSymptomOther(v);
    props.onSymptomOther(v);
  }

  const renderCheckbox = (symptom: Symptom) => (
    <FormControlLabel
      control={
        <Checkbox 
          value={symptom} 
          checked={symptoms?.includes(symptom)} 
          color="secondary" 
          onChange={e => onCheckBoxChange(e.target.checked, symptom)}
        />
      }
      label={SYMPTOM_LABEL[symptom]}
    />
  );

  return (
    <Box display="flex" flexDirection="column" alignItems="center" gap="20px" width="100%">
      <Typography variant="h2">
        Was ist Ihnen an der Straßenleuchte aufgefallen?
      </Typography>
      <Grid  container component={FormGroup}>
        {Object.values(Symptom).map((s) => (
          <Grid item xs={6} key={s}>
            {renderCheckbox(s)}
          </Grid>
        ))}
      </Grid>
      <TextField
        key="symptomOther"
        variant="standard"
        multiline
        fullWidth
        maxRows={4}
        style={{ marginTop: 10, width: "100%" }}
        placeholder="Weitere Anmerkung (Optional)"
        inputProps={{ maxLength: 400 }}
        value={symptomOther}
        onChange={e => handleSymptomOtherChange(e.target.value)}
      />
      <EmergencyDialog 
        open={openEmergencyDialog} 
        onClose={() => setOpenEmergencyDialog(false)}
        emergencySymptoms={EMERGENCY_SYMPTOMS}
      />
    </Box>
  );
};

export default React.memo(FaultSymptoms);
